//@import '~antd/dist/antd.css';

$primaryBgColour: #428bca;
$primaryBgHoverColour: #3379b5;
$siderIconSelectedColour: #BE9877;
$siderIconColour: #4d5d7c;
//$siderBgTopSplitColour: #F69321; orange rerum
$siderBgTopSplitColour: #CDCDCD;
$siderBgColour: #26344B;

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
	"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ant-menu {
	&.ant-menu-root {
		margin-top: 3px;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	monospace;
}

.ant-layout-sider-zero-width-trigger {
	top: 10px !important;
	z-index: 1;
}

.logo {
	padding: 10px;
	width: 100%;

	img {
		width: 100%;
	}
}

.rerum-app {
	min-height: 100vh;
}

.app-init-spinner {
	text-align: center;
	/*background: rgba(0,0,0,0.05);*/
	border-radius: 4px;
	padding: 30px 50px;
	margin: 20px 0;
}

.card-col:not(:last-child) {
	margin-bottom: 20px;
}


@media screen and (max-width: 860px) {

	div.table--responsive table caption {
		font-size: 1.3em;
	}

	div.table--responsive table thead {
		border: none;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}

	div.table--responsive table tr {
		border-bottom: 3px solid #ddd;
		display: block;
		margin-bottom: 20px;
		border-top: 1px solid #ddd;
		border-left: 1px solid #ddd;
	}

	div.table--responsive table td {
		border-bottom: 1px solid #ddd;
		display: block;
		font-size: .8em;
		text-align: right;
	}

	div.table--responsive table td::before {
		/*
		* aria-label has no advantage, it won't be read inside a table
		content: attr(aria-label);
		*/
		content: attr(data-label);
		float: left;
		font-weight: bold;
		text-transform: uppercase;
	}

	div.table--responsive table td:last-child {
		border-bottom: 0;
	}

	div.table--responsive {
		div.ant-table-body > table {
			border-left: none;
		}
	}

	div[class^="Form__formSection___"] {
		min-width: auto !important;
	}

	.messages-list__list-wrapper {
		.messages-list__list-wrapper--content {
			text-align: right;
		}
	}

	.bill--pay-link {
		color: rgba(0, 0, 0, 0.65);

		&:hover {
			color: rgba(0, 0, 0, 0.65) !important;
		}
	}

}

.chat-row {
	font-size: 13px;
}


.chat-message {
	background-color: #FAFAFA;
	padding: 0 5px;
	margin-bottom: 10px;
}

.left-chat-border {
	border-left: 3px solid #F05860;
}

.right-chat-border {
	border-right: 3px solid #F05860;
}

.chat-message-header {
	font-size: 12px;
}


.chat-message-body {
	font-size: 13px;
}

.maintenance-label-status- {
	/*
	OPEN,
	STARTED,
	RESOLVED,
	CLOSED
	*/
	&open {
		color: #cb5a5e;
	}

	&started {
		color: #45B6AF;
	}

	&resolved {
		color: #67809F;
	}

	&closed {
		color: #E7505A;
	}
}

.maintenance__desktop-list-wrapper,
.maintenance__desktop-chat-wrapper {
	max-height: 700px;
	overflow: scroll;
	padding: 15px 25px 5px 25px;
	border-right: 1px solid #ebedf0;
	border-left: 1px solid #ebedf0;
	border-bottom: 1px solid #ebedf0;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.maintenance__desktop-list-wrapper {
	padding-top: 0 !important;

	.ant-list {
		.ant-list-item:first-of-type {
			padding-top: 0 !important;
		}
	}
}

.maintenance__desktop-chat-wrapper {
	min-height: 802px !important;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border-top: 1px solid #ebedf0;
}

.maintenance__desktop-filter-wrapper {
	border-right: 1px solid #ebedf0;
	border-left: 1px solid #ebedf0;
	border-top: 1px solid #ebedf0;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.maintenance {
	&__attachments-list {
		.anticon.anticon-delete {
			display: none;
		}
	}
}

.bill--pay-link__desktop {
	color: white;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);

	&:hover {
		text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
		color: white !important;
	}
}

.global--back-button {
	border: none;
}

body .ant-layout {
	min-height: 100vh;
}

body .ant-btn-primary {
	&:hover {
		background-color: $primaryBgHoverColour;
	}
}

body .ant-select-open .ant-select-selection {
	border-color: $primaryBgColour;
}

body .ant-menu-item {
	.anticon {
		color: $siderIconColour;
	}
}

body .ant-menu-item.ant-menu-item-selected {
	.anticon {
		color: $siderIconSelectedColour;
	}
}

body .ant-layout-sider.ant-layout-sider-dark {
	background: linear-gradient(to bottom, $siderBgTopSplitColour 0px 100px, $siderBgColour 0% 100%);
}

body .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
	border-right: 0 !important;
}

body .ant-btn-group .ant-btn-primary:last-child:not(:first-child), .ant-btn-group .ant-btn-primary + .ant-btn-primary {
	border-left: 0 !important;
}

body .maintenance__desktop-chat-wrapper.ant-col-xs-14 {
	width: 57.8%;
}

.maintenance__desktop--chat-title {
	margin-top: 0;
	margin-bottom: 0.5em;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
}

.bg--white {
	background-color: #fff;
}

.no-x-overflow {
	overflow-x: hidden;
}

.no-y-overflow {
	overflow-y: hidden;
}

.border-radius-5 {
	border-radius: 5px;
}

.maintenance-filter__status {
	min-width: 150px;
	color: #333;

	.ant-select-selection {
		border-radius: 25px !important;
	}
}

body .maintenance-filter__new {
	border-color: #e7505a !important;
	color: #e7505a !important;
	border-radius: 25px !important;

	&:hover {
		color: #fff !important;
		border-color: #e7505a !important;
		background-color: #e7505a !important;
	}

	&:focus {
		border-color: #e7505a !important;
		color: #e7505a !important;
	}
}

.top-bar--wrapper {
	box-shadow: 0 1px 10px 0 rgba(50, 50, 50, 0.2);
	background: #ffffff;
	padding: 21px 10px;
}

.top-bar--booking-indicator-text {
	font-size: 1.3em;
}

.layout-title--title {
	font-size: 2em;
	margin-bottom: 10px;
}

.layout-title--breadcrumb-bar {
	width: 100%;
	border-radius: 5px;
}

.divider {
	&--small {
		margin: 10px 0!important;
	}
}

.ant-breadcrumb-link {
	&:hover {
		text-decoration: underline;
	}
}

.my-messages--list-wrapper {
	border-radius: 5px;
}

.antui-panel {
	position: relative;
	margin-bottom: 20px;
	background-color: #fff;
	-webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
	box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	border-radius: 2px;

	&.tool {
		background: #3598dc;
	}

	&.mail {
		background: #e7505a;
	}

	&.total {
		background: #e9573f;
	}

	&.next-bill {
		background: #8E44AD;
	}

	.panel-content {
		padding: 5px;
		padding-left: 20px;

		h5 {
			position: relative;
			color: #f5f2fd;
			font-size: 13px;
			width: 100%;
		}

		h2 {
			position: relative;
			color: #f5f2fd;
			font-size: 24px;
			line-height: 15px;
			margin-top: 15px;
			width: 100%
		}

		.anticon {
			&[class*="anticon-"] {
				// &.anticon-tool, &.anticon-mail, &.anticon-bar-chart, &.anticon-credit-card {
				position: absolute;
				opacity: .8;
				right: 0;
				top: 10px;
				line-height: 100px;
				font-size: 100px;
				color: #fff;
			}
		}
	}

}

.antui-panel-body {
	flex: auto;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	border: 1px solid #e5e5e5;
	-webkit-transition: .2s ease-out;
	-o-transition: .2s ease-out;
	transition: .2s ease-out;
	max-height: 800px;
	overflow: hidden;

	.panel-content {
		width: 100%;
	}
}

.panel-content {

}

.text-muted {

}

.util {
	&--cursor {
		cursor: pointer;
	}

	&--tile-shadow {
		box-shadow: 0 2px 8px 0 rgba(50, 50, 50, 0.4);
	}
	&--div-shadow {
		box-shadow: 0 1px 8px 0 rgba(50, 50, 50, 0.2);
		border-radius: 5px;
	}
}


.bg- {
	&white {
		background: white;
	}
}

.register-form-spinner {
	display: block;
}

.register-card {
	.ant-card-cover {
		padding: 10px;
	}
}

$spaceamounts: (5, 10, 15, 20, 21, 25, 30, 35, 40, 45, 50, 75, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
	@each $side in $sides {
		.m-#{str-slice($side, 0, 1)}-#{$space} {
			margin-#{$side}: #{$space}px !important;
		}

		.p-#{str-slice($side, 0, 1)}-#{$space} {
			padding-#{$side}: #{$space}px !important;
		}
	}
}

// styling for roomspace
$roomspacePrimaryColor: #98012E;

.rerum-client-roomspace {
	.ant-layout-sider-children {
		background-color: $roomspacePrimaryColor;
	}

	.ant-menu-dark.ant-menu-inline {
		background-color: $roomspacePrimaryColor
	}

	.ant-menu-item .anticon {
		color: rgba(255, 255, 255, 0.65)
	}

	.ant-menu-item.ant-menu-item-selected .anticon {
		color: white
	}

	.ant-menu.ant-menu-dark .ant-menu-item-selected {
		background-color: #880029e0
	}

	.ant-btn-primary {
		background-color: $roomspacePrimaryColor;
		border-color: $roomspacePrimaryColor
	}

	.ant-btn-primary:hover {
		background-color: #b5083c
	}
}

.logo-wrapper-env- {
	&bnbme, &bnbmesaudi, &staging {
		text-align: center;

		img {
			max-width: 51px !important;
		}
	}
	&propertybycapital {
		display: flex;
		justify-content: center;
	}
}

// styling for bbf
$bbfPrimaryColor: #4f5962;

.rerum-client-bbf{


		$thecollectivePrimaryColor: white;
		$thecollectiveSecondaryColor: black;
		$thecollectiveYellow: #ffeb99;
		$thecollectiveGreen: #c1e2bc;

		.rerum-client-bbf .ant-layout-sider-children{
			background-color:white!important;
		}

		.ant-layout-sider-children {
			background-color: $thecollectivePrimaryColor;
			// font-family: "RelativeBook";
		}



		body, .ant-layout-footer, .top-bar--booking-indicator-text, .ant-layout-content{
			// font-family: "RelativeBook";
		}

		h1, h2, h3, h4, h5{
			// font-family: "TheCollectiveBecome";
			position: relative;
		}

		.ant-layout{
			background-color: white
		}

	  .logo.logo-wrapper-env-bbd > img{
	    position: relative;
	    width:25%;
	    margin-left: 15px;

	  }


		.ant-menu-dark .ant-menu-item, .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a,   {
	   	color: $thecollectiveSecondaryColor;
		}

		 .ant-menu-dark .ant-menu-item-selected>a,  {
	    color: $thecollectiveSecondaryColor;
		}


		.ant-menu-dark .ant-menu-item:hover, .ant-menu-item:hover, .ant-menu-dark .ant-menu-item > a:hover {
	    color: $thecollectiveSecondaryColor;
		}


		.ant-menu-dark .ant-menu-item-selected>a:hover,  {
		 	color: $thecollectiveSecondaryColor;
	 	}

		.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
	    background-color: #ffffff00;
		}

		body .ant-layout-sider.ant-layout-sider-dark, .ant-layout-sider{
			background: #ffffff00!important;
		}

		.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item > a:hover{
			font-weight: 700
		}





		.ant-menu-item > span > a:hover{
			color: $thecollectiveSecondaryColor
		}

		.ant-menu-item > i {
			display: none
		}


		// .ant-layout-sider-children {
		// 	box-shadow: 0 0 20px -4px #c8c8c8;
		// }



		//removed classes
		//for classes that need a whitebox
		.layout-title--breadcrumb-bar, .border-radius-5.bg--white > .ant-row, .ant-layout-footer,  .maintenance__desktop-chat-wrapper .bg--white, .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20,
		.maintenance__desktop-chat-wrapper.bg--white , .ant-col.ant-col-xs-24 > .ant-list.ant-list-split > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items > .ant-list-item,
		.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20 > .ant-row, .ant-list.bg--white.ant-list-lg.ant-list-split.ant-list-bordered,  .ant-row.bg--white.p-r-15.p-l-15.border-radius-5 > .ant-col > iframe,
		.ant-col.bg--white.ant-col-xs-14, .add-extra-section, {
			box-shadow: 0 0 20px -4px #929292;
			background-color: #fff;
			position: relative;
			padding:20px;
			// padding: 30px 40px 40px;
			border-radius:5px
		}

	  .ant-row.bg--white.p-r-15.p-l-15 {
	    padding-left: 0!important;
	    padding-right: 0!important;
	  }

		.ant-row, .ant-list-item{
			margin-bottom:20px
		}


	  .ant-row.bg--white.p-r-15.p-l-15.border-radius-5 > .ant-col {
	    margin-bottom: 20px
	  }

		.ant-layout-sider-children{
			// background-color: #ffffff00;
		}

		.ant-table-wrapper .table--responsive .bg--white, .ant-table-empty, {
			background-color: #ffffff00;
		}

		.maintenance__desktop-list-wrapper{
			box-shadow: -1px 13px 20px -4px #c8c8c8;
			background-color: #fff
		}

		.maintenance__desktop-filter-wrapper{
			background-color: #fff;
			box-shadow: -1px -2px 20px 0px #c8c8c8;

		}


		.ant-menu-dark.ant-menu-inline{
			background: #ffffff00;
		}


		.ant-menu.ant-menu-dark .ant-menu-item-selected {
			background-color: #ffffff00
		}

		.ant-layout-content > div > .ant-row {
			margin-right: 0!important
		}

		.ant-layout .ant-layout-has-sider .rerum-app .rerum-client-bbf, .bg-white.border-radius-5.util--div-shadow, .util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.bg--white.m-b-15{
			background-color: white
		}

		.util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.bg--white.m-b-15{
			padding-left: 15px!important;
			padding-right: 15px!important;
		}

		.util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.m-b-15.bg--white.border-radius-5{
			padding-left: 0px!important;
			padding-right: 0px!important;
			padding-bottom: 0px!important;
			box-shadow: none;
		}

		// .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20{
		// 	padding-bottom:0!important;
		// }

		.top-bar--wrapper{
			position: relative;
			box-shadow: none;
			background: #ffffff00;
			padding: 21px 10px;
			margin-left: 10px;
			margin-right:20px
		}

		.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20 > .ant-row{
			box-shadow:none;
		}

		.ant-card-bordered{
			border-width: 0!important;
		}

		.ant-btn-primary, .ant-btn.ant-btn-primary {
			background-color: #043e84;
			border-color: #043e84;
			border-radius: 5px;
		}

		.ant-btn-primary:hover {
			background-color: #436084;
			border-color: #436084;
			border-radius: 5px;
		}
		// .ant-layout-content{
		// 	background: url("https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/images/collective+background.png");
		// 	background-color: $thecollectivePrimaryColor;
		// 	background-size: auto;
		// 	background-repeat: no-repeat;
		// }

		.ant-menu-item-selected{
			font-weight:700
		}

		.ant-layout-footer{
			background-color:#ffffff00;
			background:#ffffff00;
			box-shadow:none;
		}

		div{
			background-color: #ffffff00;
		}

		.top-bar--booking-indicator-text{
			color:$thecollectiveSecondaryColor
		}

		.a1 {
			display:none
		}

		.greenshape {

		}

		.ant-menu-item > a i, {
			display: none;
		}

		.ant-menu-dark .ant-menu-item-selected .anticon + span {
	    color: $thecollectiveSecondaryColor;
		}

		.ant-menu-item > a > .anticon, .ant-menu-item > .anticon{
			display:none;
		}

		.ant-menu-item > a > span {
			font-size: 1em!important
		}

		.ant-col.bg--white.ant-col-xs-14 > div:first-child, #addmessage, .ant-col.bg--white.ant-col-xs-14 > div > .ant-row > .ant-col.ant-col-xs-24 > h4{
			padding-left: 15px;
			padding-right:15px;
		}

		.ant-col.bg--white.ant-col-xs-14 > div:first-child{
			margin-top: 0!important
		}

		.ant-layout-content > div,  {
			margin-left: 10px!important;
			margin-right: 20px!important;
		}

		.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20 > .ant-row{
			margin-left: 0!important;
			margin-right:0!important;
		}

		.ant-table-body{
			padding-bottom:30px
		}


		.maintenance__desktop-chat-wrapper{
			display:none
		}
		.maintenance__desktop-chat-wrapper.bg--white{
			display: block;
		}

		.ant-row.my-messages--list-wrapper, .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20{
			box-shadow: none!important;
			padding:0px!important;
			background-color: #ffffff00!important
		}

		.ant-btn-background-ghost.ant-btn-primary {
	    color: $thecollectiveSecondaryColor;
	    background-color: transparent;
	    border-color: $thecollectiveSecondaryColor;
	    text-shadow: none;
		}
		.ant-btn-background-ghost.ant-btn-primary:hover {
	    color: $thecollectiveSecondaryColor;
	    background-color: transparent;
	    border-color: $thecollectiveSecondaryColor;
	    text-shadow: none;
		}

		.bg--white.p-r-15.p-l-15.p-t-15.p-b-15.border-radius-5{
			padding-left: 0px!important;
			padding-right: 0px!important;
		}


		.ant-layout-content > div > .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20:nth-child(2){
				padding-bottom: 0px!important;
		}

		.ant-menu-item-divider{
			display:none
		}

		@media(max-width: 550px){
			.add-extra-section{
				width:100%
			}
		}

		@media(max-width: 992px){
			 .ant-layout-content > div > div > .ant-row.bg--white{
				 box-shadow: 0 0 20px -4px #929292;
				 background-color: #fff;
				 position: relative;
				 padding:20px;
				 // padding: 30px 40px 40px;
				 border-radius:5px
			 }
			 .util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.m-b-15.bg--white.border-radius-5  > .ant-row{
				 margin-right: 0!important;
				 margin-left: 0!important;
			 }
		}

		@media(min-width: 1400px){
			.ant-layout-content{
				margin-left:5%;
				margin-right:15%;
			}
	    .a1 {
	      left: -15%;
	    }
		}

}
.logo-wrapper-env-bbf{
	background-color: white;
	border-right-color: #eff1f4;
	border-right-style: solid;
	border-right-width: 0px;
	height:84px
}
.layout-title--title{
	padding-top:15px
}
.logo-wrapper-env- {
	&bnbme, &bnbmesaudi, &staging {
		text-align: center;

		img {
			max-width: 51px !important;
		}
	}
}

$flyingButlerPrimaryColor:#6da8ad;

.rerum-client-flyingbutler {
	.ant-layout-sider-children{
		background-color: $flyingButlerPrimaryColor;
	}
	.ant-menu-dark.ant-menu-inline{
		background-color: $flyingButlerPrimaryColor
	}
	.ant-menu-item .anticon{
		color:rgba(255, 255, 255, 0.65)
	}
	.ant-menu-item.ant-menu-item-selected .anticon{
		color:white
	}
	.ant-menu.ant-menu-dark .ant-menu-item-selected{
		background-color: #79b3b7
	}
	.ant-btn-primary {
		background-color: $flyingButlerPrimaryColor;
		border-color: $flyingButlerPrimaryColor
	}
	.ant-btn-primary:hover {
		background-color: #79b3b7;
    border-color: #79b3b7;
	}
  .ant-menu-dark .ant-menu-item > a{
    color: white;
  }
  .ant-avatar-lg.ant-avatar-icon {
    background-color: $flyingButlerPrimaryColor!important;
    color: white!important
  }
}

.logo-wrapper-env- {
	&bnbme, &bnbmesaudi, &staging {
		text-align: center;
		img {
			max-width: 51px!important;
		}
	}
}

$flyingButlerPrimaryColor:#6da8ad;

.rerum-client-flyingbutler {
	.ant-layout-sider-children{
		background-color: $flyingButlerPrimaryColor;
	}
	.ant-menu-dark.ant-menu-inline{
		background-color: $flyingButlerPrimaryColor;
	}
	.ant-menu-item .anticon{
		color:rgba(255, 255, 255, 0.65);
	}

	.ant-menu-item.ant-menu-item-selected {
		color:white
	}
	.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
	    background-color: #79b3b7;
	}

	.logo-wrapper-env-flyingbutler{
		background-color: white;
    border-right-color: #eff1f4;
    border-right-style: solid;
    border-right-width: 1px;
	}

	.ant-btn-primary {
		background-color: $flyingButlerPrimaryColor;
		border-color: $flyingButlerPrimaryColor
	}
	.ant-btn-primary:hover {
		background-color: #79b3b7;
    border-color: #79b3b7;
	}
  .ant-menu-dark .ant-menu-item > a{
    color: white;
  }
  .ant-avatar-lg.ant-avatar-icon {
    background-color: $flyingButlerPrimaryColor!important;
    color: white!important
  }
}

.logo-wrapper-env- {
	&bnbme, &bnbmesaudi, &staging {
		text-align: center;
		img {
			max-width: 51px!important;
		}
	}
}

// styling for the collective

				@font-face {
            font-family: "TheCollectiveBecome";
            src: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/TheCollectiveBecome-Medium.eot') format('embedded-opentype'),
            url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/TheCollectiveBecome-Medium.woff') format('woff'),
            url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/TheCollectiveBecome-Medium.woff2') format('woff2'),
            url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/TheCollectiveBecome-Medium.ttf') format('truetype');
        }

        @font-face {
            font-family: "RelativeBook";
            src: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/Relative-Book-Web.eot?#iefix') format('embedded-opentype'),
            url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/Relative-Book-Web.woff') format('woff'),
            url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/Relative-Book-Web.woff2') format('woff2'),
            url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/Relative-Book-Web.ttf') format('truetype');
        }

        @font-face {
            font-family: "RelativeBold";
            src: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/Relative-Bold-Web.eot?#iefix') format('embedded-opentype'),
            url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/Relative-Bold-Web.woff') format('woff'),
            url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/Relative-Bold-Web.woff2') format('woff2'),
            url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/Relative-Bold-Web.ttf') format('truetype');
        }

        @font-face {
            font-family: "RelativeMedium";
            src: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/Relative-Medium-Web.eot?#iefix') format('embedded-opentype'),
            url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/Relative-Medium-Web.woff') format('woff'),
            url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/Relative-Medium-Web.woff2') format('woff2'),
            url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/Relative-Medium-Web.ttf') format('truetype');
        }

        @font-face {
            font-family: "RelativeFaux";
            src: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/Relative-Faux-Web.eot?#iefix') format('embedded-opentype'),
            url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/Relative-Faux-Web.woff') format('woff'),
            url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/Relative-Faux-Web.woff2') format('woff2'),
            url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/Relative-Faux-Web.ttf') format('truetype');
        }

        @font-face {
            font-family: "CircularStd";
            src: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/CircularStd-Book.otf') format('opentype');
        }

        @font-face {
            font-family: "CircularStd Medium";
            src: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/CircularStd-Medium.otf') format('opentype');
        }

        @font-face {
            font-family: "CircularStd Bold";
            src: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/thecollective-fonts/CircularStd-Bold.otf') format('opentype');
        }

.rerum-client-thecollective {


	$thecollectivePrimaryColor: white;
	$thecollectiveSecondaryColor: black;
	$thecollectiveYellow: #ffeb99;
	$thecollectiveGreen: #c1e2bc;



	.ant-layout-sider-children {
		background-color: $thecollectivePrimaryColor;
		font-family: "RelativeBook";
	}

	// hide the breadcrumb
	.layout-title--breadcrumb-bar{
		display:none
	}

	body, .ant-layout-footer, .top-bar--booking-indicator-text, .ant-layout-content{
		font-family: "RelativeBook";
	}

	h1, h2, h3, h4, h5{
		font-family: "TheCollectiveBecome";
		position: relative;
	}

	.ant-layout{
		background-color: white
	}

  .logo.logo-wrapper-env-thecollective > img{
    position: relative;
    width:25%;
    margin-left: 15px;

  }


	.ant-menu-dark .ant-menu-item, .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a,   {
   	color: $thecollectiveSecondaryColor;
	}

	 .ant-menu-dark .ant-menu-item-selected>a,  {
    color: $thecollectiveSecondaryColor;
	}


	.ant-menu-dark .ant-menu-item:hover, .ant-menu-item:hover, .ant-menu-dark .ant-menu-item > a:hover {
    color: $thecollectiveSecondaryColor;
	}


	.ant-menu-dark .ant-menu-item-selected>a:hover,  {
	 	color: $thecollectiveSecondaryColor;
 	}

	.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ffffff00;
	}

	body .ant-layout-sider.ant-layout-sider-dark, .ant-layout-sider{
		background: #ffffff00!important;
	}

	.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item > a:hover{
		font-weight: 700
	}





	.ant-menu-item > span > a:hover{
		color: $thecollectiveSecondaryColor
	}

	.ant-menu-item > i {
		display: none
	}


	// .ant-layout-sider-children {
	// 	box-shadow: 0 0 20px -4px #c8c8c8;
	// }



	//removed classes
	//for classes that need a whitebox
	.layout-title--breadcrumb-bar, .border-radius-5.bg--white > .ant-row, .ant-layout-footer,  .maintenance__desktop-chat-wrapper .bg--white, .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20,
	.maintenance__desktop-chat-wrapper.bg--white , .ant-col.ant-col-xs-24 > .ant-list.ant-list-split > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items > .ant-list-item,
	.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20 > .ant-row, .ant-list.bg--white.ant-list-lg.ant-list-split.ant-list-bordered,  .ant-row.bg--white.p-r-15.p-l-15.border-radius-5 > .ant-col > iframe,
	.ant-col.bg--white.ant-col-xs-14, .add-extra-section, {
		box-shadow: 0 0 20px -4px #929292;
		background-color: #fff;
		position: relative;
		padding:20px;
		// padding: 30px 40px 40px;
		border-radius:5px
	}

  .ant-row.bg--white.p-r-15.p-l-15 {
    padding-left: 0!important;
    padding-right: 0!important;
  }

	.ant-row, .ant-list-item{
		margin-bottom:20px
	}


  .ant-row.bg--white.p-r-15.p-l-15.border-radius-5 > .ant-col {
    margin-bottom: 20px
  }

	.ant-layout-sider-children{
		background-color: #ffffff00;
	}

	.ant-table-wrapper .table--responsive .bg--white, .ant-table-empty, {
		background-color: #ffffff00;
	}

	.maintenance__desktop-list-wrapper{
		box-shadow: -1px 13px 20px -4px #c8c8c8;
		background-color: #fff
	}

	.maintenance__desktop-filter-wrapper{
		background-color: #fff;
		box-shadow: -1px -2px 20px 0px #c8c8c8;

	}


	.ant-menu-dark.ant-menu-inline{
		background: #ffffff00;
	}


	.ant-menu.ant-menu-dark .ant-menu-item-selected {
		background-color: #ffffff00
	}

	.ant-layout-content > div > .ant-row {
		margin-right: 0!important
	}

	.ant-layout .ant-layout-has-sider .rerum-app .rerum-client-thecollective, .bg-white.border-radius-5.util--div-shadow, .util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.bg--white.m-b-15{
		background-color: white
	}

	.util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.bg--white.m-b-15{
		padding-left: 15px!important;
		padding-right: 15px!important;
	}

	.util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.m-b-15.bg--white.border-radius-5{
		padding-left: 0px!important;
		padding-right: 0px!important;
		padding-bottom: 0px!important;
		box-shadow: none;
	}

	// .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20{
	// 	padding-bottom:0!important;
	// }

	.top-bar--wrapper{
		position: relative;
		box-shadow: none;
		background: #ffffff00;
		padding: 21px 10px;
		margin-left: 10px;
		margin-right:20px
	}

	.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20 > .ant-row{
		box-shadow:none;
	}

	.ant-card-bordered{
		border-width: 0!important;
	}

	.ant-btn-primary, .ant-btn.ant-btn-primary {
		background-color: $thecollectiveSecondaryColor;
		border-color: $thecollectiveSecondaryColor;
		border-radius: 15px;
	}

	.ant-btn-primary:hover {
		background-color: $thecollectiveSecondaryColor;
		border-color: $thecollectiveSecondaryColor;
		border-radius: 15px;
	}
	// .ant-layout-content{
	// 	background: url("https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/images/collective+background.png");
	// 	background-color: $thecollectivePrimaryColor;
	// 	background-size: auto;
	// 	background-repeat: no-repeat;
	// }

	.ant-layout-footer{
		background-color:#ffffff00;
		background:#ffffff00;
		box-shadow:none;
	}

	div{
		background-color: #ffffff00;
	}

	.top-bar--booking-indicator-text{
		color:$thecollectiveSecondaryColor
	}

	.a1 {
		position: fixed;
		z-index: 0;
		top: 0;
		left: 0;
		background-repeat: no-repeat;
		background-image: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/images/yellow_pattern.svg');
		width: 100%;
    height: 986px;
    background-position: 94% 112%;
		background-color: white;
	}

	.greenshape {
		position: fixed;
		z-index: 0;
		top: 660px;
		right: 0;
		width: 346px;
		height: 577px;
		background-repeat: no-repeat;
		background-image: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/images/green_pattern.svg');
	}

	.ant-menu-item > a i, {
		display: none;
	}

	.ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: $thecollectiveSecondaryColor;
	}

	.ant-menu-item > a > .anticon, .ant-menu-item > .anticon{
		display:none;
	}

	.ant-menu-item > a > span {
		font-size: 1em!important
	}

	.ant-col.bg--white.ant-col-xs-14 > div:first-child, #addmessage, .ant-col.bg--white.ant-col-xs-14 > div > .ant-row > .ant-col.ant-col-xs-24 > h4{
		padding-left: 15px;
		padding-right:15px;
	}

	.ant-col.bg--white.ant-col-xs-14 > div:first-child{
		margin-top: 0!important
	}

	.ant-layout-content > div,  {
		margin-left: 10px!important;
		margin-right: 20px!important;
	}

	.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20 > .ant-row{
		margin-left: 0!important;
		margin-right:0!important;
	}

	.ant-table-body{
		padding-bottom:30px
	}


	.maintenance__desktop-chat-wrapper{
		display:none
	}
	.maintenance__desktop-chat-wrapper.bg--white{
		display: block;
	}

	.ant-row.my-messages--list-wrapper, .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20{
		box-shadow: none!important;
		padding:0px!important;
		background-color: #ffffff00!important
	}

	.ant-btn-background-ghost.ant-btn-primary {
    color: $thecollectiveSecondaryColor;
    background-color: transparent;
    border-color: $thecollectiveSecondaryColor;
    text-shadow: none;
	}
	.ant-btn-background-ghost.ant-btn-primary:hover {
    color: $thecollectiveSecondaryColor;
    background-color: transparent;
    border-color: $thecollectiveSecondaryColor;
    text-shadow: none;
	}

	.bg--white.p-r-15.p-l-15.p-t-15.p-b-15.border-radius-5{
		padding-left: 0px!important;
		padding-right: 0px!important;
	}


	.ant-layout-content > div > .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20:nth-child(2){
			padding-bottom: 0px!important;
	}

	@media(max-width: 550px){
		.add-extra-section{
			width:100%
		}
	}

	@media(max-width: 992px){
		 .ant-layout-content > div > div > .ant-row.bg--white{
			 box-shadow: 0 0 20px -4px #929292;
			 background-color: #fff;
			 position: relative;
			 padding:20px;
			 // padding: 30px 40px 40px;
			 border-radius:5px
		 }
		 .util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.m-b-15.bg--white.border-radius-5  > .ant-row{
			 margin-right: 0!important;
			 margin-left: 0!important;
		 }
	}

	@media(min-width: 1400px){
		.ant-layout-content{
			margin-left:5%;
			margin-right:15%;
		}
    .a1 {
      left: -15%;
    }
	}

}


.rerum-client-thecollectivelive {


	$thecollectivePrimaryColor: white;
	$thecollectiveSecondaryColor: black;
	$thecollectiveYellow: #ffeb99;
	$thecollectiveGreen: #c1e2bc;



	.ant-layout-sider-children {
		background-color: $thecollectivePrimaryColor;
		font-family: "RelativeBook";
	}

	// hide the breadcrumb
	.layout-title--breadcrumb-bar{
		display:none
	}

	body, .ant-layout-footer, .top-bar--booking-indicator-text, .ant-layout-content{
		font-family: "RelativeBook";
	}

	h1, h2, h3, h4, h5{
		font-family: "TheCollectiveBecome";
		position: relative;
	}

	.ant-layout{
		background-color: white
	}

  .logo.logo-wrapper-env-thecollectivelive > img{
    position: relative;
    width:25%;
    margin-left: 15px;

  }


	.ant-menu-dark .ant-menu-item, .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a,   {
   	color: $thecollectiveSecondaryColor;
	}

	 .ant-menu-dark .ant-menu-item-selected>a,  {
    color: $thecollectiveSecondaryColor;
	}


	.ant-menu-dark .ant-menu-item:hover, .ant-menu-item:hover, .ant-menu-dark .ant-menu-item > a:hover {
    color: $thecollectiveSecondaryColor;
	}


	.ant-menu-dark .ant-menu-item-selected>a:hover,  {
	 	color: $thecollectiveSecondaryColor;
 	}

	.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ffffff00;
	}

	body .ant-layout-sider.ant-layout-sider-dark, .ant-layout-sider{
		background: #ffffff00!important;
	}

	.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item > a:hover{
		font-weight: 700
	}





	.ant-menu-item > span > a:hover{
		color: $thecollectiveSecondaryColor
	}

	.ant-menu-item > i {
		display: none
	}


	// .ant-layout-sider-children {
	// 	box-shadow: 0 0 20px -4px #c8c8c8;
	// }



	//removed classes
	//for classes that need a whitebox
	.layout-title--breadcrumb-bar, .border-radius-5.bg--white > .ant-row, .ant-layout-footer,  .maintenance__desktop-chat-wrapper .bg--white, .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20,
	.maintenance__desktop-chat-wrapper.bg--white , .ant-col.ant-col-xs-24 > .ant-list.ant-list-split > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items > .ant-list-item,
	.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20 > .ant-row, .ant-list.bg--white.ant-list-lg.ant-list-split.ant-list-bordered,  .ant-row.bg--white.p-r-15.p-l-15.border-radius-5 > .ant-col > iframe,
	.ant-col.bg--white.ant-col-xs-14, .add-extra-section, {
		box-shadow: 0 0 20px -4px #929292;
		background-color: #fff;
		position: relative;
		padding:20px;
		// padding: 30px 40px 40px;
		border-radius:5px
	}

  .ant-row.bg--white.p-r-15.p-l-15 {
    padding-left: 0!important;
    padding-right: 0!important;
  }

	.ant-row, .ant-list-item{
		margin-bottom:20px
	}


  .ant-row.bg--white.p-r-15.p-l-15.border-radius-5 > .ant-col {
    margin-bottom: 20px
  }

	.ant-layout-sider-children{
		background-color: #ffffff00;
	}

	.ant-table-wrapper .table--responsive .bg--white, .ant-table-empty, {
		background-color: #ffffff00;
	}

	.maintenance__desktop-list-wrapper{
		box-shadow: -1px 13px 20px -4px #c8c8c8;
		background-color: #fff
	}

	.maintenance__desktop-filter-wrapper{
		background-color: #fff;
		box-shadow: -1px -2px 20px 0px #c8c8c8;

	}


	.ant-menu-dark.ant-menu-inline{
		background: #ffffff00;
	}


	.ant-menu.ant-menu-dark .ant-menu-item-selected {
		background-color: #ffffff00
	}

	.ant-layout-content > div > .ant-row {
		margin-right: 0!important
	}

	.ant-layout .ant-layout-has-sider .rerum-app .rerum-client-thecollectivelive, .bg-white.border-radius-5.util--div-shadow, .util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.bg--white.m-b-15{
		background-color: white
	}

	.util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.bg--white.m-b-15{
		padding-left: 15px!important;
		padding-right: 15px!important;
	}

	.util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.m-b-15.bg--white.border-radius-5{
		padding-left: 0px!important;
		padding-right: 0px!important;
		padding-bottom: 0px!important;
		box-shadow: none;
	}

	// .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20{
	// 	padding-bottom:0!important;
	// }

	.top-bar--wrapper{
		position: relative;
		box-shadow: none;
		background: #ffffff00;
		padding: 21px 10px;
		margin-left: 10px;
		margin-right:20px
	}

	.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20 > .ant-row{
		box-shadow:none;
	}

	.ant-card-bordered{
		border-width: 0!important;
	}

	.ant-btn-primary, .ant-btn.ant-btn-primary {
		background-color: $thecollectiveSecondaryColor;
		border-color: $thecollectiveSecondaryColor;
		border-radius: 15px;
	}

	.ant-btn-primary:hover {
		background-color: $thecollectiveSecondaryColor;
		border-color: $thecollectiveSecondaryColor;
		border-radius: 15px;
	}
	// .ant-layout-content{
	// 	background: url("https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/images/collective+background.png");
	// 	background-color: $thecollectivePrimaryColor;
	// 	background-size: auto;
	// 	background-repeat: no-repeat;
	// }

	.ant-layout-footer{
		background-color:#ffffff00;
		background:#ffffff00;
		box-shadow:none;
	}

	div{
		background-color: #ffffff00;
	}

	.top-bar--booking-indicator-text{
		color:$thecollectiveSecondaryColor
	}

	.a1 {
		position: fixed;
		z-index: 0;
		top: 0;
		left: 0;
		background-repeat: no-repeat;
		background-image: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/images/yellow_pattern.svg');
		width: 100%;
    height: 986px;
    background-position: 94% 112%;
		background-color: white;
	}

	.greenshape {
		position: fixed;
		z-index: 0;
		top: 660px;
		right: 0;
		width: 346px;
		height: 577px;
		background-repeat: no-repeat;
		background-image: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/images/green_pattern.svg');
	}

	.ant-menu-item > a i, {
		display: none;
	}

	.ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: $thecollectiveSecondaryColor;
	}

	.ant-menu-item > a > .anticon, .ant-menu-item > .anticon{
		display:none;
	}

	.ant-menu-item > a > span {
		font-size: 1em!important
	}

	.ant-col.bg--white.ant-col-xs-14 > div:first-child, #addmessage, .ant-col.bg--white.ant-col-xs-14 > div > .ant-row > .ant-col.ant-col-xs-24 > h4{
		padding-left: 15px;
		padding-right:15px;
	}

	.ant-col.bg--white.ant-col-xs-14 > div:first-child{
		margin-top: 0!important
	}

	.ant-layout-content > div,  {
		margin-left: 10px!important;
		margin-right: 20px!important;
	}

	.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20 > .ant-row{
		margin-left: 0!important;
		margin-right:0!important;
	}

	.ant-table-body{
		padding-bottom:30px
	}


	.maintenance__desktop-chat-wrapper{
		display:none
	}
	.maintenance__desktop-chat-wrapper.bg--white{
		display: block;
	}

	.ant-row.my-messages--list-wrapper, .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20{
		box-shadow: none!important;
		padding:0px!important;
		background-color: #ffffff00!important
	}

	.ant-btn-background-ghost.ant-btn-primary {
    color: $thecollectiveSecondaryColor;
    background-color: transparent;
    border-color: $thecollectiveSecondaryColor;
    text-shadow: none;
	}
	.ant-btn-background-ghost.ant-btn-primary:hover {
    color: $thecollectiveSecondaryColor;
    background-color: transparent;
    border-color: $thecollectiveSecondaryColor;
    text-shadow: none;
	}

	.bg--white.p-r-15.p-l-15.p-t-15.p-b-15.border-radius-5{
		padding-left: 0px!important;
		padding-right: 0px!important;
	}


	.ant-layout-content > div > .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20:nth-child(2){
			padding-bottom: 0px!important;
	}

	@media(max-width: 550px){
		.add-extra-section{
			width:100%
		}
	}

	@media(max-width: 992px){
		 .ant-layout-content > div > div > .ant-row.bg--white{
			 box-shadow: 0 0 20px -4px #929292;
			 background-color: #fff;
			 position: relative;
			 padding:20px;
			 // padding: 30px 40px 40px;
			 border-radius:5px
		 }
		 .util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.m-b-15.bg--white.border-radius-5  > .ant-row{
			 margin-right: 0!important;
			 margin-left: 0!important;
		 }
	}

	@media(min-width: 1400px){
		.ant-layout-content{
			margin-left:5%;
			margin-right:15%;
		}
    .a1 {
      left: -15%;
    }
	}

}



.rerum-client-demo{


	$thecollectivePrimaryColor: white;
	$thecollectiveSecondaryColor: black;
	$thecollectiveYellow: #ffeb99;
	$thecollectiveGreen: #c1e2bc;



	.ant-layout-sider-children {
		background-color: $thecollectivePrimaryColor;
		// font-family: "RelativeBook";
	}



	body, .ant-layout-footer, .top-bar--booking-indicator-text, .ant-layout-content{
		// font-family: "RelativeBook";
	}

	h1, h2, h3, h4, h5{
		// font-family: "TheCollectiveBecome";
		position: relative;
	}

	.ant-layout{
		background-color: white
	}

  .logo.logo-wrapper-env-demo > img{
    position: relative;
    width:100%;
    margin-left: 15px;

  }


	.ant-menu-dark .ant-menu-item, .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a,   {
   	color: $thecollectiveSecondaryColor;
	}

	 .ant-menu-dark .ant-menu-item-selected>a,  {
    color: $thecollectiveSecondaryColor;
	}


	.ant-menu-dark .ant-menu-item:hover, .ant-menu-item:hover, .ant-menu-dark .ant-menu-item > a:hover {
    color: $thecollectiveSecondaryColor;
	}


	.ant-menu-dark .ant-menu-item-selected>a:hover,  {
	 	color: $thecollectiveSecondaryColor;
 	}

	.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ffffff00;
	}

	body .ant-layout-sider.ant-layout-sider-dark, .ant-layout-sider{
		background: #ffffff00!important;
	}

	.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item > a:hover{
		font-weight: 700
	}





	.ant-menu-item > span > a:hover{
		color: $thecollectiveSecondaryColor
	}

	.ant-menu-item > i {
		display: none
	}


	// .ant-layout-sider-children {
	// 	box-shadow: 0 0 20px -4px #c8c8c8;
	// }



	//removed classes
	//for classes that need a whitebox
	.layout-title--breadcrumb-bar, .border-radius-5.bg--white > .ant-row, .ant-layout-footer,  .maintenance__desktop-chat-wrapper .bg--white, .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20,
	.maintenance__desktop-chat-wrapper.bg--white , .ant-col.ant-col-xs-24 > .ant-list.ant-list-split > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items > .ant-list-item,
	.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20 > .ant-row, .ant-list.bg--white.ant-list-lg.ant-list-split.ant-list-bordered,  .ant-row.bg--white.p-r-15.p-l-15.border-radius-5 > .ant-col > iframe,
	.ant-col.bg--white.ant-col-xs-14, .add-extra-section, {
		box-shadow: 0 0 20px -4px #929292;
		background-color: #fff;
		position: relative;
		padding:20px;
		// padding: 30px 40px 40px;
		border-radius:5px
	}

  .ant-row.bg--white.p-r-15.p-l-15 {
    padding-left: 0!important;
    padding-right: 0!important;
  }

	.ant-row, .ant-list-item{
		margin-bottom:20px
	}


  .ant-row.bg--white.p-r-15.p-l-15.border-radius-5 > .ant-col {
    margin-bottom: 20px
  }

	.ant-layout-sider-children{
		background-color: #ffffff00;
	}

	.ant-table-wrapper .table--responsive .bg--white, .ant-table-empty, {
		background-color: #ffffff00;
	}

	.maintenance__desktop-list-wrapper{
		box-shadow: -1px 13px 20px -4px #c8c8c8;
		background-color: #fff
	}

	.maintenance__desktop-filter-wrapper{
		background-color: #fff;
		box-shadow: -1px -2px 20px 0px #c8c8c8;

	}


	.ant-menu-dark.ant-menu-inline{
		background: #ffffff00;
	}


	.ant-menu.ant-menu-dark .ant-menu-item-selected {
		background-color: #ffffff00
	}

	.ant-layout-content > div > .ant-row {
		margin-right: 0!important
	}

	.ant-layout .ant-layout-has-sider .rerum-app .rerum-client-thecollective, .bg-white.border-radius-5.util--div-shadow, .util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.bg--white.m-b-15{
		background-color: white
	}

	.util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.bg--white.m-b-15{
		padding-left: 15px!important;
		padding-right: 15px!important;
	}

	.util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.m-b-15.bg--white.border-radius-5{
		padding-left: 0px!important;
		padding-right: 0px!important;
		padding-bottom: 0px!important;
		box-shadow: none;
	}

	// .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20{
	// 	padding-bottom:0!important;
	// }

	.top-bar--wrapper{
		position: relative;
		box-shadow: none;
		background: #ffffff00;
		padding: 21px 10px;
		margin-left: 10px;
		margin-right:20px
	}

	.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20 > .ant-row{
		box-shadow:none;
	}

	.ant-card-bordered{
		border-width: 0!important;
	}

	.ant-btn-primary, .ant-btn.ant-btn-primary {
		background-color: $thecollectiveSecondaryColor;
		border-color: $thecollectiveSecondaryColor;
		border-radius: 15px;
	}

	.ant-btn-primary:hover {
		background-color: $thecollectiveSecondaryColor;
		border-color: $thecollectiveSecondaryColor;
		border-radius: 15px;
	}
	// .ant-layout-content{
	// 	background: url("https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/images/collective+background.png");
	// 	background-color: $thecollectivePrimaryColor;
	// 	background-size: auto;
	// 	background-repeat: no-repeat;
	// }

	.ant-layout-footer{
		background-color:#ffffff00;
		background:#ffffff00;
		box-shadow:none;
	}

	div{
		background-color: #ffffff00;
	}

	.top-bar--booking-indicator-text{
		color:$thecollectiveSecondaryColor
	}

	.a1 {
		position: fixed;
		z-index: 0;
		top: 0;
		left: 0;
		background-repeat: no-repeat;
		// background-image: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/images/yellow_pattern.svg');
		width: 100%;
    height: 986px;
    background-position: 94% 112%;
		background-color: #fafafa;
	}

	.greenshape {
		position: fixed;
		z-index: 0;
		top: 660px;
		right: 0;
		width: 346px;
		height: 577px;
		background-repeat: no-repeat;
		background-color: #fafafa
		// background-image: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/images/green_pattern.svg');
	}

	.ant-layout{
		background-color: #fafafa
	}

	.ant-menu-item > a i, {
		display: none;
	}

	.ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: $thecollectiveSecondaryColor;
	}

	.ant-menu-item > a > .anticon, .ant-menu-item > .anticon{
		display:none;
	}

	.ant-menu-item > a > span {
		font-size: 1em!important
	}

	.ant-col.bg--white.ant-col-xs-14 > div:first-child, #addmessage, .ant-col.bg--white.ant-col-xs-14 > div > .ant-row > .ant-col.ant-col-xs-24 > h4{
		padding-left: 15px;
		padding-right:15px;
	}

	.ant-col.bg--white.ant-col-xs-14 > div:first-child{
		margin-top: 0!important
	}

	.ant-layout-content > div,  {
		margin-left: 10px!important;
		margin-right: 20px!important;
	}

	.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20 > .ant-row{
		margin-left: 0!important;
		margin-right:0!important;
	}

	.ant-table-body{
		padding-bottom:30px
	}


	.maintenance__desktop-chat-wrapper{
		display:none
	}
	.maintenance__desktop-chat-wrapper.bg--white{
		display: block;
	}

	.ant-row.my-messages--list-wrapper, .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20{
		box-shadow: none!important;
		padding:0px!important;
		background-color: #ffffff00!important
	}

	.ant-btn-background-ghost.ant-btn-primary {
    color: $thecollectiveSecondaryColor;
    background-color: transparent;
    border-color: $thecollectiveSecondaryColor;
    text-shadow: none;
	}
	.ant-btn-background-ghost.ant-btn-primary:hover {
    color: $thecollectiveSecondaryColor;
    background-color: transparent;
    border-color: $thecollectiveSecondaryColor;
    text-shadow: none;
	}

	.bg--white.p-r-15.p-l-15.p-t-15.p-b-15.border-radius-5{
		padding-left: 0px!important;
		padding-right: 0px!important;
	}


	.ant-layout-content > div > .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20:nth-child(2){
			padding-bottom: 0px!important;
	}

	.ant-btn.ant-btn-primary{
		background-color: #f69425;
		border-color:#f69425;
		font-weight: 700
	}

	.ant-btn.ant-btn-primary:hover{
		background-color: #e28822;
		border-color:#e28822;
		font-weight: 700
	}

	.ant-btn-danger{
		background-color: #1a1f4a;
		border-color:#1a1f4a;
		font-weight: 700
	}

	.ant-btn-danger:hover{
		background-color: #1a1f4abd;
		border-color:#1a1f4abd;
		font-weight: 700
	}

	.add-extra-section #submit:not(.ant-btn-danger) {
		background-color:#f69425
	}

	.ant-badge .ant-avatar-circle, .ant-list-item-meta-avatar .ant-avatar-circle{
		background-color:#f69425!important;
		color:white!important
	}

	.ant-btn-group > .ant-btn:first-child:not(:last-child){
		border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
		border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
	}

	.ant-btn-group > .ant-btn:last-child:not(:first-child){
		border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
		border-top-right-radius: 15px;
		border-bottom-right-radius: 15px;

	}

	.ant-btn-group > .ant-btn:only-child {
    border-radius: 15px;
	}

	@media(max-width: 550px){
		.add-extra-section{
			width:100%
		}
	}

	@media(max-width: 992px){
		 .ant-layout-content > div > div > .ant-row.bg--white{
			 box-shadow: 0 0 20px -4px #929292;
			 background-color: #fff;
			 position: relative;
			 padding:20px;
			 // padding: 30px 40px 40px;
			 border-radius:5px
		 }
		 .util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.m-b-15.bg--white.border-radius-5  > .ant-row{
			 margin-right: 0!important;
			 margin-left: 0!important;
		 }
	}

	@media(min-width: 1400px){
		.ant-layout-content{
			margin-left:5%;
			margin-right:15%;
		}
    .a1 {
      left: -15%;
    }
	}

}



.rerum-client-mcdemo{


	$thecollectivePrimaryColor: white;
	$thecollectiveSecondaryColor: black;
	$thecollectiveYellow: #ffeb99;
	$thecollectiveGreen: #c1e2bc;



	.ant-layout-sider-children {
		background-color: $thecollectivePrimaryColor;
		// font-family: "RelativeBook";
	}



	body, .ant-layout-footer, .top-bar--booking-indicator-text, .ant-layout-content{
		// font-family: "RelativeBook";
	}

	h1, h2, h3, h4, h5{
		// font-family: "TheCollectiveBecome";
		position: relative;
	}

	.ant-layout{
		background-color: white
	}

  .logo.logo-wrapper-env-mcdemo > img{
    position: relative;
    width:100%;
    margin-left: 15px;

  }


	.ant-menu-dark .ant-menu-item, .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a,   {
   	color: $thecollectiveSecondaryColor;
	}

	 .ant-menu-dark .ant-menu-item-selected>a,  {
    color: $thecollectiveSecondaryColor;
	}


	.ant-menu-dark .ant-menu-item:hover, .ant-menu-item:hover, .ant-menu-dark .ant-menu-item > a:hover {
    color: $thecollectiveSecondaryColor;
	}


	.ant-menu-dark .ant-menu-item-selected>a:hover,  {
	 	color: $thecollectiveSecondaryColor;
 	}

	.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ffffff00;
	}

	body .ant-layout-sider.ant-layout-sider-dark, .ant-layout-sider{
		background: #ffffff00!important;
	}

	.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item > a:hover{
		font-weight: 700
	}





	.ant-menu-item > span > a:hover{
		color: $thecollectiveSecondaryColor
	}

	.ant-menu-item > i {
		display: none
	}


	// .ant-layout-sider-children {
	// 	box-shadow: 0 0 20px -4px #c8c8c8;
	// }



	//removed classes
	//for classes that need a whitebox
	.layout-title--breadcrumb-bar, .border-radius-5.bg--white > .ant-row, .ant-layout-footer,  .maintenance__desktop-chat-wrapper .bg--white, .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20,
	.maintenance__desktop-chat-wrapper.bg--white , .ant-col.ant-col-xs-24 > .ant-list.ant-list-split > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items > .ant-list-item,
	.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20 > .ant-row, .ant-list.bg--white.ant-list-lg.ant-list-split.ant-list-bordered,  .ant-row.bg--white.p-r-15.p-l-15.border-radius-5 > .ant-col > iframe,
	.ant-col.bg--white.ant-col-xs-14, .add-extra-section, {
		box-shadow: 0 0 20px -4px #929292;
		background-color: #fff;
		position: relative;
		padding:20px;
		// padding: 30px 40px 40px;
		border-radius:5px
	}

  .ant-row.bg--white.p-r-15.p-l-15 {
    padding-left: 0!important;
    padding-right: 0!important;
  }

	.ant-row, .ant-list-item{
		margin-bottom:20px
	}


  .ant-row.bg--white.p-r-15.p-l-15.border-radius-5 > .ant-col {
    margin-bottom: 20px
  }

	.ant-layout-sider-children{
		background-color: #ffffff00;
	}

	.ant-table-wrapper .table--responsive .bg--white, .ant-table-empty, {
		background-color: #ffffff00;
	}

	.maintenance__desktop-list-wrapper{
		box-shadow: -1px 13px 20px -4px #c8c8c8;
		background-color: #fff
	}

	.maintenance__desktop-filter-wrapper{
		background-color: #fff;
		box-shadow: -1px -2px 20px 0px #c8c8c8;

	}


	.ant-menu-dark.ant-menu-inline{
		background: #ffffff00;
	}


	.ant-menu.ant-menu-dark .ant-menu-item-selected {
		background-color: #ffffff00
	}

	.ant-layout-content > div > .ant-row {
		margin-right: 0!important
	}

	.ant-layout .ant-layout-has-sider .rerum-app .rerum-client-thecollective, .bg-white.border-radius-5.util--div-shadow, .util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.bg--white.m-b-15{
		background-color: white
	}

	.util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.bg--white.m-b-15{
		padding-left: 15px!important;
		padding-right: 15px!important;
	}

	.util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.m-b-15.bg--white.border-radius-5{
		padding-left: 0px!important;
		padding-right: 0px!important;
		padding-bottom: 0px!important;
		box-shadow: none;
	}

	// .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20{
	// 	padding-bottom:0!important;
	// }

	.top-bar--wrapper{
		position: relative;
		box-shadow: none;
		background: #ffffff00;
		padding: 21px 10px;
		margin-left: 10px;
		margin-right:20px
	}

	.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20 > .ant-row{
		box-shadow:none;
	}

	.ant-card-bordered{
		border-width: 0!important;
	}

	.ant-btn-primary, .ant-btn.ant-btn-primary {
		background-color: $thecollectiveSecondaryColor;
		border-color: $thecollectiveSecondaryColor;
		border-radius: 15px;
	}

	.ant-btn-primary:hover {
		background-color: $thecollectiveSecondaryColor;
		border-color: $thecollectiveSecondaryColor;
		border-radius: 15px;
	}
	// .ant-layout-content{
	// 	background: url("https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/images/collective+background.png");
	// 	background-color: $thecollectivePrimaryColor;
	// 	background-size: auto;
	// 	background-repeat: no-repeat;
	// }

	.ant-layout-footer{
		background-color:#ffffff00;
		background:#ffffff00;
		box-shadow:none;
	}

	div{
		background-color: #ffffff00;
	}

	.top-bar--booking-indicator-text{
		color:$thecollectiveSecondaryColor
	}

	.a1 {
		position: fixed;
		z-index: 0;
		top: 0;
		left: 0;
		background-repeat: no-repeat;
		// background-image: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/images/yellow_pattern.svg');
		width: 100%;
    height: 986px;
    background-position: 94% 112%;
		background-color: #fafafa;
	}

	.greenshape {
		position: fixed;
		z-index: 0;
		top: 660px;
		right: 0;
		width: 346px;
		height: 577px;
		background-repeat: no-repeat;
		background-color: #fafafa
		// background-image: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/images/green_pattern.svg');
	}

	.ant-layout{
		background-color: #fafafa
	}

	.ant-menu-item > a i, {
		display: none;
	}

	.ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: $thecollectiveSecondaryColor;
	}

	.ant-menu-item > a > .anticon, .ant-menu-item > .anticon{
		display:none;
	}

	.ant-menu-item > a > span {
		font-size: 1em!important
	}

	.ant-col.bg--white.ant-col-xs-14 > div:first-child, #addmessage, .ant-col.bg--white.ant-col-xs-14 > div > .ant-row > .ant-col.ant-col-xs-24 > h4{
		padding-left: 15px;
		padding-right:15px;
	}

	.ant-col.bg--white.ant-col-xs-14 > div:first-child{
		margin-top: 0!important
	}

	.ant-layout-content > div,  {
		margin-left: 10px!important;
		margin-right: 20px!important;
	}

	.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20 > .ant-row{
		margin-left: 0!important;
		margin-right:0!important;
	}

	.ant-table-body{
		padding-bottom:30px
	}


	.maintenance__desktop-chat-wrapper{
		display:none
	}
	.maintenance__desktop-chat-wrapper.bg--white{
		display: block;
	}

	.ant-row.my-messages--list-wrapper, .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20{
		box-shadow: none!important;
		padding:0px!important;
		background-color: #ffffff00!important
	}

	.ant-btn-background-ghost.ant-btn-primary {
    color: $thecollectiveSecondaryColor;
    background-color: transparent;
    border-color: $thecollectiveSecondaryColor;
    text-shadow: none;
	}
	.ant-btn-background-ghost.ant-btn-primary:hover {
    color: $thecollectiveSecondaryColor;
    background-color: transparent;
    border-color: $thecollectiveSecondaryColor;
    text-shadow: none;
	}

	.bg--white.p-r-15.p-l-15.p-t-15.p-b-15.border-radius-5{
		padding-left: 0px!important;
		padding-right: 0px!important;
	}


	.ant-layout-content > div > .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20:nth-child(2){
			padding-bottom: 0px!important;
	}

	.ant-btn.ant-btn-primary{
		background-color: #f69425;
		border-color:#f69425;
		font-weight: 700
	}

	.ant-btn.ant-btn-primary:hover{
		background-color: #e28822;
		border-color:#e28822;
		font-weight: 700
	}

	.ant-btn-danger{
		background-color: #1a1f4a;
		border-color:#1a1f4a;
		font-weight: 700
	}

	.ant-btn-danger:hover{
		background-color: #1a1f4abd;
		border-color:#1a1f4abd;
		font-weight: 700
	}

	.add-extra-section #submit:not(.ant-btn-danger) {
		background-color:#f69425
	}

	.ant-badge .ant-avatar-circle, .ant-list-item-meta-avatar .ant-avatar-circle{
		background-color:#f69425!important;
		color:white!important
	}

	.ant-btn-group > .ant-btn:first-child:not(:last-child){
		border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
		border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
	}

	.ant-btn-group > .ant-btn:last-child:not(:first-child){
		border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
		border-top-right-radius: 15px;
		border-bottom-right-radius: 15px;

	}

	.ant-btn-group > .ant-btn:only-child {
    border-radius: 15px;
	}

	@media(max-width: 550px){
		.add-extra-section{
			width:100%
		}
	}

	@media(max-width: 992px){
		 .ant-layout-content > div > div > .ant-row.bg--white{
			 box-shadow: 0 0 20px -4px #929292;
			 background-color: #fff;
			 position: relative;
			 padding:20px;
			 // padding: 30px 40px 40px;
			 border-radius:5px
		 }
		 .util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.m-b-15.bg--white.border-radius-5  > .ant-row{
			 margin-right: 0!important;
			 margin-left: 0!important;
		 }
	}

	@media(min-width: 1400px){
		.ant-layout-content{
			margin-left:5%;
			margin-right:15%;
		}
    .a1 {
      left: -15%;
    }
	}

}

// styling for vitanovae
.rerum-client-vitanovae{
	.logo-wrapper-env-vitanovae {
		height: 81px;
    text-align-last: center;
	}
	.logo-wrapper-env-vitanovae img{
		height: 68px;
	}
	.logo img{
		width: unset
	}
}


// styling for canarywharf
@font-face {
		font-family: "Right-Gothic";
		src: url('https://s3.eu-west-1.amazonaws.com/cdn.resharmonics.com/canarywharf/canarywharf-fonts/PPRightGothic-Medium.ttf') format('truetype');
}

@font-face {
		font-family: "Right-Grotesk";
		src: url('https://s3.eu-west-1.amazonaws.com/cdn.resharmonics.com/canarywharf/canarywharf-fonts/PPRightGrotesk-Regular.ttf') format('truetype');
}

.rerum-client-canarywharf{


	$thecollectivePrimaryColor: #081a30;
	$thecollectiveSecondaryColor: white;
	$thecollectiveYellow: black;
	$thecollectiveGreen: #081a30;

	$canaryWharfBlue: #081a30;
	$canaryWharfWhite: white;
	$canaryWharfBlack: black;
	$canaryWharfGreen: #29423B;

	.ant-layout-sider-children {
		background-color: $thecollectivePrimaryColor;
		// font-family: "RelativeBook";
	}



	body, .ant-layout-footer, .top-bar--booking-indicator-text, .ant-layout-content, .nav-text{
		font-family: "Right-Gothic"!important;
	}

	h1, h2, h3, h4, h5{
		font-family: "Right-Gothic";
		position: relative;
	}

	.ant-layout{
		background-color: $canaryWharfBlue!important
	}

  .logo.logo-wrapper-env-mcdemo > img{
    position: relative;
    width:100%;
    margin-left: 15px;

  }

	.util--div-shadow .ant-row .ant-col div h3, .util--div-shadow h3{
		color: $canaryWharfBlack!important
	}


	.ant-menu-dark .ant-menu-item, .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a,   {
   	color: $thecollectiveSecondaryColor;
	}

	 .ant-menu-dark .ant-menu-item-selected>a,  {
    color: $thecollectiveSecondaryColor;
	}


	.ant-menu-dark .ant-menu-item:hover, .ant-menu-item:hover, .ant-menu-dark .ant-menu-item > a:hover {
    color: $thecollectiveSecondaryColor;
	}


	.ant-menu-dark .ant-menu-item-selected>a:hover,  {
	 	color: $thecollectiveSecondaryColor;
 	}

	.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    // background-color: #ffffff00;
	}

	body .ant-layout-sider.ant-layout-sider-dark, .ant-layout-sider{
		// background: #ffffff00!important;
	}

	.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item > a:hover{
		font-weight: 700
	}





	.ant-menu-item > span > a:hover{
		color: $thecollectiveSecondaryColor
	}

	.ant-menu-item > i {
		display: none
	}


	// .ant-layout-sider-children {
	// 	box-shadow: 0 0 20px -4px #c8c8c8;
	// }

	.greenshape{
		background-color: $canaryWharfBlue!important;
	}

	.layout-title--title, .ant-layout-content div .ant-col-xs-24 h3, .ant-layout-content div .ant-col-xs-24 h4, .ant-layout-content div div h3, .ant-layout-footer, .ant-space .ant-space-item, .ant-empty-description{
		color: $canaryWharfWhite!important;
	}

	.util--div-shadow h3, .ant-row .ant-col .util--div-shadow h3, .ant-space-horizontal .ant-space-item{
		color:$canaryWharfBlack!important
	}

	//removed classes
	//for classes that need a whitebox
	.layout-title--breadcrumb-bar, .border-radius-5.bg--white > .ant-row, .ant-layout-footer,  .maintenance__desktop-chat-wrapper .bg--white, .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20,
	.maintenance__desktop-chat-wrapper.bg--white , .ant-col.ant-col-xs-24 > .ant-list.ant-list-split > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items > .ant-list-item,
	.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20 > .ant-row, .ant-list.bg--white.ant-list-lg.ant-list-split.ant-list-bordered,  .ant-row.bg--white.p-r-15.p-l-15.border-radius-5 > .ant-col > iframe,
	.ant-col.bg--white.ant-col-xs-14, .add-extra-section, {
		box-shadow: 0 0 20px -4px #929292;
		background-color: #fff;
		position: relative;
		padding:20px;
		// padding: 30px 40px 40px;
		border-radius:5px
	}

  .ant-row.bg--white.p-r-15.p-l-15 {
    padding-left: 0!important;
    padding-right: 0!important;
  }

	.ant-row, .ant-list-item{
		margin-bottom:20px
	}


  .ant-row.bg--white.p-r-15.p-l-15.border-radius-5 > .ant-col {
    margin-bottom: 20px
  }

	.ant-layout-sider-children{
		background-color: $canaryWharfBlue;
	}

	.ant-table-wrapper .table--responsive .bg--white, .ant-table-empty, {
		background-color: #ffffff00;
	}

	.maintenance__desktop-list-wrapper{
		// box-shadow: -1px 13px 20px -4px #c8c8c8;
		background-color: #fff
	}

	.maintenance__desktop-filter-wrapper{
		background-color: #fff;
		// box-shadow: -1px -2px 20px 0px #c8c8c8;

	}


	.ant-menu-dark.ant-menu-inline{
		background: #ffffff00;
	}


	.ant-menu.ant-menu-dark .ant-menu-item-selected {
		background-color: $canaryWharfBlue
	}

	.ant-layout-content > div > .ant-row {
		margin-right: 0!important
	}

	.ant-layout .ant-layout-has-sider .rerum-app .rerum-client-thecollective, .bg-white.border-radius-5.util--div-shadow, .util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.bg--white.m-b-15{
		background-color: white
	}

	.util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.bg--white.m-b-15{
		padding-left: 15px!important;
		padding-right: 15px!important;
	}

	.util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.m-b-15.bg--white.border-radius-5{
		padding-left: 0px!important;
		padding-right: 0px!important;
		padding-bottom: 0px!important;
		box-shadow: none;
	}

	// .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20{
	// 	padding-bottom:0!important;
	// }

	.top-bar--wrapper{
		position: relative;
		box-shadow: none;
		background: #ffffff00;
		padding: 21px 10px;
		margin-left: 10px;
		margin-right:20px
	}

	.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20 > .ant-row{
		box-shadow:none;
	}

	.ant-card-bordered{
		border-width: 0!important;
	}

	.ant-btn-primary, .ant-btn.ant-btn-primary {
		background-color: $thecollectiveSecondaryColor;
		border-color: $thecollectiveSecondaryColor;
		border-radius: 15px;
	}

	.ant-btn-primary:hover {
		background-color: $thecollectiveSecondaryColor;
		border-color: $thecollectiveSecondaryColor;
		border-radius: 15px;
	}
	// .ant-layout-content{
	// 	background: url("https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/images/collective+background.png");
	// 	background-color: $thecollectivePrimaryColor;
	// 	background-size: auto;
	// 	background-repeat: no-repeat;
	// }

	.ant-layout-footer{
		background-color:#ffffff00;
		background:#ffffff00;
		box-shadow:none;
	}

	div{
		background-color: #ffffff00;
	}

	.top-bar--booking-indicator-text{
		color:$thecollectiveSecondaryColor
	}

	.m-b-15 .util--div-shadow .ant-row{
		box-shadow: none;
	}

	.ant-menu > .ant-menu-item-divider{
		background-color: $canaryWharfBlue
	}

	.greenshape {
		position: fixed;
		z-index: 0;
		top: 660px;
		right: 0;
		width: 346px;
		height: 577px;
		background-repeat: no-repeat;
		background-color: #fafafa
		// background-image: url('https://s3-eu-west-1.amazonaws.com/cdn.resharmonics.com/thecollective/images/green_pattern.svg');
	}

	.ant-layout{
		background-color: #fafafa
	}

	.ant-menu-item > a i, {
		display: none;
	}

	.ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: $thecollectiveSecondaryColor;
	}

	.ant-menu-item > a > .anticon, .ant-menu-item > .anticon{
		display:none;
	}

	.ant-menu-item > a > span {
		font-size: 1em!important
	}

	.ant-col.bg--white.ant-col-xs-14 > div:first-child, #addmessage, .ant-col.bg--white.ant-col-xs-14 > div > .ant-row > .ant-col.ant-col-xs-24 > h4{
		padding-left: 15px;
		padding-right:15px;
	}

	.ant-col.bg--white.ant-col-xs-14 > div:first-child{
		margin-top: 0!important
	}

	.ant-layout-content > div,  {
		margin-left: 10px!important;
		margin-right: 20px!important;
	}

	.p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20 > .ant-row{
		margin-left: 0!important;
		margin-right:0!important;
	}

	.ant-table-body{
		padding-bottom:30px
	}


	.maintenance__desktop-chat-wrapper{
		display:none
	}
	.maintenance__desktop-chat-wrapper.bg--white{
		display: block;
	}

	.ant-row.my-messages--list-wrapper, .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20.m-t-20{
		box-shadow: none!important;
		padding:0px!important;
		background-color: #ffffff00!important
	}

	.ant-btn-background-ghost.ant-btn-primary {
    color: $thecollectiveSecondaryColor;
    background-color: transparent;
    border-color: $thecollectiveSecondaryColor;
    text-shadow: none;
	}
	.ant-btn-background-ghost.ant-btn-primary:hover {
    color: $thecollectiveSecondaryColor;
    background-color: transparent;
    border-color: $thecollectiveSecondaryColor;
    text-shadow: none;
	}

	.bg--white.p-r-15.p-l-15.p-t-15.p-b-15.border-radius-5{
		padding-left: 0px!important;
		padding-right: 0px!important;
	}


	.ant-layout-content > div > .p-r-15.p-l-15.border-radius-5.bg--white.p-b-30.p-t-20:nth-child(2){
			padding-bottom: 0px!important;
	}

	.ant-btn.ant-btn-primary{
		background-color: $canaryWharfGreen;
		border-color: $canaryWharfGreen;
		font-weight: 700
	}

	.ant-btn-background-ghost a{
		color: $canaryWharfGreen!important
	}

	.ant-btn.ant-btn-primary:hover{
		background-color: $canaryWharfBlue;
		border-color: $canaryWharfBlue;
		font-weight: 700
	}

	.ant-btn-danger{
		background-color: #1a1f4a;
		border-color:#1a1f4a;
		font-weight: 700
	}

	.ant-btn-danger:hover{
		background-color: #1a1f4abd;
		border-color:#1a1f4abd;
		font-weight: 700
	}

	.add-extra-section #submit:not(.ant-btn-danger) {
		background-color:$canaryWharfGreen
	}

	.ant-badge .ant-avatar-circle, .ant-list-item-meta-avatar .ant-avatar-circle{
		background-color:$canaryWharfGreen!important;
		color:white!important
	}

	.ant-btn-group > .ant-btn:first-child:not(:last-child){
		border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
		border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
	}

	.ant-btn-group > .ant-btn:last-child:not(:first-child){
		border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
		border-top-right-radius: 15px;
		border-bottom-right-radius: 15px;

	}

	.bg--white .ant-row .ant-col h3, .bg--white .ant-row .ant-col h4{
		color: $canaryWharfBlack!important
	}

	.ant-btn-group > .ant-btn:only-child {
    border-radius: 15px;
	}

	.tool{
		background-color: #ff484a;
	}

	.total{
		background-color: #ddfb74;
	}


	.mail{
		background-color: #b9e9f2;
	}

	.next-bill{
		background-color: white;

	}

	.antui-panel-body{
		border-color:$canaryWharfBlue!important;
	}

	.panel-content h2, .panel-content h5, .panel-content span svg{
		color:$canaryWharfBlue!important;
	}

	input, textarea, .ant-select-selection-item{
		font-family: "Right-Grotesk";
	}

	@media(max-width: 550px){
		.add-extra-section{
			width:100%
		}
	}

	@media(max-width: 992px){
		 .ant-layout-content > div > div > .ant-row.bg--white{
			 box-shadow: 0 0 20px -4px #929292;
			 background-color: #fff;
			 position: relative;
			 padding:20px;
			 // padding: 30px 40px 40px;
			 border-radius:5px
		 }
		 .util--div-shadow.p-t-10.p-r-5.p-b-10.p-l-5.m-b-15.bg--white.border-radius-5  > .ant-row{
			 margin-right: 0!important;
			 margin-left: 0!important;
		 }
	}

	@media(min-width: 1400px){
		.ant-layout-content{
			margin-left:5%;
			margin-right:15%;
		}
    .a1 {
      left: -15%;
    }
	}

}



#register {
	.ant-form-item-label {
		text-align: left !important;
	}
}

.google-map {
	&__height-fix {
		top: 10px !important;
		right: 0 !important;
		left:0!important;
		margin-left:auto;
		margin-right:auto;
		width: 98% !important;
		height: 95% !important;
	}
}

.no-booking- {
   &canarywharf {
        color:white!important
   }
}
